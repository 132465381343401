<template>
   <tbody v-if="purchase">
      <tr @click="expanded = !expanded" :class="expanded ? 'orange font-semibold' : 'hover:bg-gray-200'">
        <td>{{purchase.id}}</td>
        <td colspan="2"></td>
        <td class="text-right">{{sumBy(purchase.lines, r => r.quantity)}}</td>
        <td class="text-right">{{numberFormat(sumBy(purchase.lines, r => r.amount))}}</td>
        <td>{{purchase.purchaseStatusId === 3 ? 'Fakturert' : ''}}</td>
        <td>{{invoiceId}}</td>
        <td>{{purchase.vendorRef}}</td>
        <td class="text-right">{{purchase.amountSettled > 0 ? numberFormat(purchase.amountSettled) : ''}}</td>
        <td class="text-center">{{purchase.deliveryDate | date}}</td>
        <td>
          <a class="underline" @click.stop="showDialog = true">
            {{ $tk('Reports.Show', true) }} {{ purchase.invoiceTypeId > 1 ? $tk('Reports.ReverseInvoice').toLowerCase() : $tk('Reports.InvoiceBasis')}}
          </a>
        </td>
        <td :rowspan="expanded ? purchase.lines.length + 1 : 1" class="text-center">
          <font-awesome-icon v-if="!expanded" :icon="['far', 'chevron-down' ]" />
          <font-awesome-icon v-else :icon="['far', 'chevron-up' ]" />
        </td>
      </tr>
      <tr v-show="expanded" class="font-semibold orange" v-for="(line, index) in purchase.lines" :key="index">
        <td class="pl-2 text-right">{{line.lineNumber}}</td>
        <td>{{line.name}}</td>
        <td class="whitespace-no-wrap">{{line.qualityName}} {{ !line.treatmentIsDefault ? `(${line.treatmentName})` : '' }}</td>
        <td class="text-right">{{line.quantity}}</td>
        <td class="text-right">{{line.isCommission ? 'Kom' : (line.amount > 0 ? numberFormat(line.amount) : '0,00')}}</td>
        <td v-if="index == 0" :rowspan="purchase.lines.length" colspan="6"></td>
      </tr>
      <purchase-dialog
        v-if="showDialog"
        :purchase="purchase"
        @close="showDialog = false" />
    </tbody>
</template>

<script>
  // import http from "@/http"
  import { join, map, sumBy } from "lodash"
  import PurchaseDialog from '../dialogs/PurchaseDialog.vue'
  export default {
    name: "PurchaseLine",
    props: {
      purchase: {
        type: Object,
        default: undefined
      }
    },
    data() {
      return {
        invoices: [],
        showDialog: false,
        expanded: false
      }
    },
    components: {
      PurchaseDialog
    },
    computed: {
      invoiceId() {
        return join(map(this.purchase.targetInvoiceIds, r => r), ', ')
      }
    },
    methods: {
      sumBy,
      numberFormat (number) {
        return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), { minimumFractionDigits: 2 }).format(number)
      }
    }
  }
</script>

<style>
  tr.orange {
    @apply bg-orange-200
  }
</style>