<template>
  <div class="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded">
    <div class="relative flex-1 overflow-y-scroll">
      <p-loader v-if="isLoading" />
      <table v-else class="data-table">
        <thead>
          <tr>
            <th v-html="$tk('Reports.OrderNumber')" class="w-32"></th>
            <th v-html="$tk('Reports.Product')" class="w-32"></th>
            <th v-html="$tk('Reports.Quality')" class="w-48"></th>
            <th v-html="$tk('Reports.Quantity')" class="w-8 right"></th>            
            <th v-html="$tk('Reports.Amount')" class="w-12 right"></th>
            <th v-html="$tk('Reports.Status')"></th>
            <th v-html="$tk('Reports.InvoiceNumber')" class="max-w-md whitespace-no-wrap"></th>
            <th v-html="$tk('Common.General.Reference')"></th>
            <th v-html="$tk('Reports.Paid')" class="right"></th>
            <th v-html="$tk('Reports.DeliveryDate')" class="w-32 center"></th>
            <th v-html="$tk('Reports.Document')"></th>
            <th></th>
          </tr>
        </thead>
        <template v-if="isLoading === false">
          <purchase-lines v-for="(purchase, index) in filteredPurchases" :key="index" :purchase="purchase" />
        </template>
      </table>

    <portal to="header">
      <div class="ml-1 text-xl font-medium text-gray-900">{{$tk('Reports.InvoiceBasisFromSR', true)}}</div>
    </portal>

    <portal to="filters">
      <div class="flex space-x-2">
        
        <PInput 
          class="w-48"
          :placeholder="$tk('Common.General.Search')" 
          :clearable="true"
          iconInsideLeft="search"
          v-model="term" 
          :wait="300"
        />

        <PDatePicker 
          class="w-36" 
          :right-align="true" 
          :placeholder="$tk('Common.General.FromDate')" 
          v-model="invoiceDateLower" 
          :clearable="false" 
          :allOpen="true" 
        />
        
        <PDatePicker 
          class="w-36" 
          :right-align="true" 
          :placeholder="$tk('Common.General.ToDate')" 
          v-model="invoiceDateUpper" 
          :clearable="false" 
          :allOpen="true" 
          :rightAlign="true" 
        />
      </div>
    </portal>

    <portal to="actions">
      <PButton 
        color="secondary" 
        icon="file-excel"
        :loading="isDownloading"
        @click="onDownload"
      >
        Excel
      </PButton>
    </portal>
      
   </div>
  </div>
</template>

<script>
  import http from "@/http"
  import { mapGetters } from "vuex"
  import { format, sub, startOfMonth, lastDayOfMonth } from "date-fns"
  import { orderBy, filter, join, map } from 'lodash'
  import PurchaseLines from '../components/PurchaseLines.vue'
  import fileDownload from 'js-file-download'

  export default {
    name: "Purchases",

    data () {
      return {
        term: "",
        purchases: [],
        isLoading: true,
        isDownloading: false,
        invoiceDateUpper: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
        invoiceDateLower: format(startOfMonth(sub(new Date(), { months: 2 })), "yyyy-MM-dd")
      }
    },

    computed: {
    
      ...mapGetters(['user', 'location']),
      
      filters () {
        return this.invoiceDateUpper + '-' + this.invoiceDateLower
      },
    
      params () {
        let obj = {
          createdDateLower: this.invoiceDateLower,
          createdDateUpper: this.invoiceDateUpper,
          withLines: true    
        }
        if (this.user.isCustomerAdmin) {
          obj.customerId = this.location.customerId
        } else {
          obj.locationId = this.location.id
        }
        return obj
      },

      filteredPurchases () {
        return filter(this.purchases, purchase => {
          const combinedIds = (purchase.id + ' ' + join(map(purchase.targetInvoiceIds, r => r), ' ') + ' ' + purchase.vendorRef).toLowerCase()          
          return combinedIds.indexOf(this.term.toLowerCase()) >= 0
        })
      }

    },

    watch: {
      async filters() {
        this.loadPurchases()
      }
    },
    components: {
      PurchaseLines,
    },
    methods: {
      
      async loadPurchases () {
        this.isLoading = true        
        let response = await http.get("Purchases",  { params:  this.params })
        this.purchases = orderBy(response, ['targetInvoiceIds', 'id.length', ['id']], ['desc', 'desc', 'desc'])
        this.isLoading = false
      },

      async onDownload () {
        this.isDownloading = true
        const data = await http.get("Purchases", { params: { ...this.params, excel: "download"}, responseType: "blob"})
        fileDownload(data, `${format(new Date(), "yyyy-MM-dd")}_purchases.xlsx`)
        this.isDownloading = false
      }

    },
    async created () {
      this.loadPurchases()
    }
  }
</script>

<style scoped>
  thead > tr >th {
    @apply sticky;
  }
  thead > tr > th.center {
    text-align: center;
  }
</style>